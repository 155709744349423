<template>
    <div>
		<template v-if="loading">
			<PuSkeleton height="200px" />
		</template>
		<section v-else-if="Object.keys(detail).length > 0 && detail.vehicle_used_optionals.length > 0" class="content-new-ones-intern-optionals">
			<div class="container">
				<div class="content-new-ones-intern-optionals-header">
					<p class="content-new-ones-intern-optionals-header-title">{{ $t("vehicle_used_details.text_optionals")|stripHtml }}</p>
				</div>
				<div class="content-new-ones-intern-optionals-itens">
					<ul class="content-new-ones-intern-optionals-itens-elements">
						<li class="content-new-ones-intern-optionals-itens-text" 
							v-for="(optional, index) in detail.vehicle_used_optionals" :key="index">
							{{ optional.name }}	
						</li>
					</ul>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
		...mapGetters('VehicleUsed', ['loading', 'detail']),
	}
}
</script>