<template>
  <div class="new-ones-intern">
    <SectionImages />
    <SectionOptionals />
    <SectionObservations />
    <SectionOtherVehicles />
  </div>
</template>

<script>
import SectionImages from "./SectionImages";
import SectionOptionals from "./SectionOptionals";
import SectionObservations from "./SectionObservations";
import SectionOtherVehicles from "./SectionOtherVehicles";
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    SectionImages,
    SectionOptionals,
    SectionObservations,
    SectionOtherVehicles,
  },
  computed: {
    ...mapGetters("VehicleUsed", ["loading", "detail"]),
  },
  methods: {
    ...mapActions("VehicleUsed", ["loadVehicleDetail"]),
    ...mapActions("Site", ["toggleFormFixed"]),
  },
  mounted() {
    this.loadVehicleDetail(this.$route.params);

    if (Object.keys(this.detail).length == 0 && !this.loading) {
      this.$router.push({name: 'vehicleUseds'});
    }
  },
  data() {
    return {     
    };
  },
};
</script>

<style lang="scss">
.content-new-ones-intern {
  padding-top: 30px;
  &-itens {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    &-vehicle {
      position: relative;
      @media (max-width: 991px) {
        padding: 0;
      }
      &-brand {
        text-align: left;
        font-size: 16px;
        color: var(--main-color);
        font-family: "Font Bold";
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 1;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      &-name {
        text-align: left;
        position: relative;
        font-size: 40px;
        font-family: "Font Medium";
        color: #2f2f32;
        line-height: 1.2;
        margin-bottom: 10px;
        padding-bottom: 6px;
        @media (max-width: 991px) {
          font-size: 32px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
        }
        span {
          font-family: "Font Black";
        }
      }
      .gallery-top {
        .swiper-pagination {
          &-bullets {
            bottom: 0;
          }
          &-bullet {
            height: 18px;
            width: 52px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 10px;
            background: var(--white);
            margin: 0px 5.75px;
            opacity: 1;
            @media (max-width: 991px) {
              height: 5px;
              width: 16px;
              margin: 0px 1.75px;
            }
            &-active {
              background: var(--main-color);
            }
          }
        }
        @media (max-width: 991px) {
          .swiper-button-prev,
          .swiper-button-next {
            svg {
              max-width: 13px;
            }
          }
        }
      }
      .gallery-thumbs {
        padding-top: 20px;
        @media (max-width: 991px) {
          padding-top: 6px;
        }
        .swiper-wrapper {
          margin-left: -37%;
        }
        .swiper-slide {
          cursor: pointer;
          width: 25%;
          .gallery-thumbs-image {
            opacity: 0.49;
          }
        }
        .swiper-slide-active {
          .gallery-thumbs-image {
            opacity: 1;
          }
        }
        &-image {
          position: relative;
          display: inline-block;
        }
      }
    }
    &-piece {
      margin-left: 10px;
      @media (max-width: 991px) {
        margin-top: 30px;
        margin-left: initial;
        padding: 0;
      }
      &-header {
        border-bottom: 4px solid var(--main-color);
        padding-bottom: 10px;
        @media (max-width: 991px) {
          text-align: center;
        }
        p {
          &:first-of-type {
            font-size: 18px;
            color: #2f2f32;
            font-family: "Font Bold";
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
          }
          &:nth-of-type(2) {
            font-size: 16px;
            color: var(--main-color);
            font-family: "Font Bold";
            margin-bottom: 0;
            line-height: 1;
            span {
              font-size: 32px;
            }
          }
          &:last-of-type {
            font-size: 16px;
            color: #2f2f32;
            font-family: "Font Medium";
            margin-bottom: 0;
            margin-top: 10px;
            line-height: 1;
          }
        }
      }
      &-information {
        &-elements {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px;
          border-bottom: 1px solid #6f6f6f;
          &-svg {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 70px;
            p {
              font-size: 10px;
              color: var(--main-color);
              font-family: "Font Medium";
              text-transform: uppercase;
              margin-bottom: 0px;
            }
          }
          &-item {
            padding: 8px 0px;
            p {
              font-size: 20px;
              color: var(--black);
              font-family: "Font Bold";
              margin-bottom: 0;
              line-height: 1;
            }
          }
        }
      }
      &-call {
        width: 200px;
        height: 50px;
        background: var(--main-color);
        border: 2px solid var(--main-color);
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin: 20px auto;
        font-size: 16px;
        font-family: "Font Bold";
        transition: 500ms;
        svg {
          fill: var(--white);
        }
        &:hover,
        &:focus {
          background: var(--white);
          color: var(--main-color);
          svg {
            fill: var(--main-color);
          }
        }
      }
      &-social {
        &-itens {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          p {
            font-size: 12px;
            color: #868e96;
            margin: 0 10px 0 0;
            font-family: "Font Medium";
          }
          &-facebook {
            background: #3b5998;
            border: 1px solid #3b5998;
            border-radius: 4px;
            height: 42px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
          &-whatsapp {
            background: #25d366;
            border: 1px solid #25d366;
            border-radius: 4px;
            height: 42px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    &-form {
      @media (max-width: 991px) {
        padding: 0;
        margin-top: 30px;
      }
      .content-form-cotation-header h2 {
        @media (min-width: 992px) and (max-width: 1500px) {
          font-size: 16px;
        }
      }
      .content-form-cotation {
        position: relative;
        top: initial;
        max-width: 100%;
        &-header {
          h2 {
            @media (max-width: 991px) {
              font-size: 16px;
            }
          }
        }
      }
      &-modal {
        width: max-content;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      &-item {
        position: relative;
        transform: none;
        right: initial;
        top: initial;
        opacity: 1;
        z-index: 1;
        pointer-events: initial;
      }
    }
  }
  &-optionals {
    margin-top: 30px;
    background: #f6f6f6;
    padding: 30px 0px 30px 0px;
    box-shadow: 0px 0px 3px #ccc;
    &-header {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      &-title {
        font-size: 22px;
        color: var(--black);
        font-family: "Font Bold";
        border-bottom: 4px solid var(--main-color);
        width: max-content;
        text-transform: uppercase;
        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
    }
    &-itens {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 991px) {
        padding-left: initial;
      }
      &-elements {
        width: 33.3%;
        margin-top: 10px;
        list-style: none;
        @media (max-width: 991px) {
          width: 50%;
        }
      }
      &-text {
        text-align: left;
        font-size: 16px;
        color: #2f2f32;
        font-family: "Font Bold";
        margin-bottom: 10px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
        &::before {
          content: "•";
          color: var(--main-color);
          margin-right: 10px;
        }
      }
    }
  }
  &-observe {
    background: rgba(59, 59, 59, 0.9);
    padding: 30px 0px 30px 0px;
    &-header {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      &-title {
        font-size: 22px;
        color: var(--white);
        font-family: "Font Bold";
        border-bottom: 4px solid var(--main-color);
        width: max-content;
        text-transform: uppercase;
        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
    }
    &-itens {
      &-text {
        text-align: left;
        font-size: 16px;
        color: var(--white);
        font-family: "Font Light";
        margin-bottom: 10px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }
}
.content-home-new-ones {
    padding: 80px 0px;
    background: #F3F5F8;
    @media (max-width: 991px){
      padding: 30px 0px;
    }
    &-itens {
      &-header {
        text-align: left;
        margin-bottom: 50px;
        @media (max-width: 991px){
          text-align: center;
        }
        .title {
          position: relative;
          font-size: 40px;
          font-family: 'Font Regular';
          color: #2F2F32;
          margin-bottom: 0;
          padding-bottom: 10px;
          @media (max-width: 991px){
            font-size: 20px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 67px;
            display: block;
            background: var(--main-color);
            box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            border-radius: 10px;
            @media (max-width: 991px){
              left: 50%;
              transform: translateX(-50%);
            }
          }
          span {
            font-family: 'Font Black';
          }
        }
      } 
      &-body {
        .content-btn {
          display: flex;
          justify-content: flex-end;
          @media (max-width: 991px){
            justify-content: center;
          }
          .btn-view-plus {
            height: 63px;
            width: 155px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #43433F;
            border: 1px solid #43433F;
            border-radius: 10px;
            box-shadow: 0px 3px 5px rgba(0,7,4,0.36);
            font-size: 20px;
            font-family: 'Font Medium';
            color: var(--white);
            text-decoration: none !important;
            transition: 500ms;
            @media (max-width: 991px){
              font-size: 16px;
              height: 50px;
              width: 130px;
            }
            &:hover,&:focus {
              cursor: pointer;
              background: var(--white);
              color: #43433F;
            }
          }
        }
      }
    }
}
</style>