<template>
  <div class="content-piece-acessories">    
    <section class="content-piece-acessories-description">
			<div class="content-piece-acessories-description-itens container">
				<div class="content-piece-acessories-description-itens-header">
					<h1 class="title">{{ $t("policy_privacy.title_page")|stripHtml }}</h1>
				</div>				
			</div>
		</section>
		<section class="content-piece-acessories-elements">
			<div class="content-piece-acessories-elements-itens container">
				<div v-if="Object.keys(layout).length > 0" class="row">
					<div class="col-lg-12">
						<div 
							style="padding: 20px; text-align: justify;"
							v-html="($t('policy_privacy.text_page')).replaceAll('COMPANY_NAME', layout.company.name)"></div>
					</div>					
				</div>
			</div>
		</section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	components: {    
	},
	computed:{
		...mapGetters('Site', ['layout'])
	}
};
</script>

<style lang="scss">
.content-piece-acessories {
	&-description {
		background: #3D5369;
		padding: 50px 0px;
		text-align: left;
		@media (max-width: 991px){
			text-align: center;
		}
		&-itens {
			&-header {
				margin-bottom: 30px;
				.title {
					position: relative;
					font-size: 40px;
					font-family: 'Font Black';
					color: var(--white);
					margin-bottom: 0;
					padding-bottom: 6px;
					@media (max-width: 991px){
						font-size: 20px;
						font-family: 'Font Medium';
					}
					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						height: 4px;
						width: 128px;
						display: block;
						background: var(--main-color);
						box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
						border-radius: 10px;
						@media (max-width: 991px){
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
			&-body {
				width: 60%;
				@media (max-width: 991px){
					width: 100%;
				}
				@media (min-width: 992px) and (max-width: 1199px){
					width: 50%;
				}
				p {
					font-size: 18px;
					font-family: 'Font Regular';
					color: var(--white);
					margin-bottom: 0;
					@media (max-width: 991px){
						font-size: 16px;
					}
				}
			}
		}
	}
}
.content-piece-acessories-elements {
	padding: 100px 0px;
	@media (max-width: 991px){
		padding-top: 50px;
		padding-bottom: 20px;
	}
	&-itens {
		.content-filter {
			border: 1px solid #F5F5F5;
			border-radius: 3px;
			box-shadow: 0px 2px 6px rgba(0,0,0,0.16);
			@media (max-width: 991px){
				margin-bottom: 30px;
			}
			&-search {
				padding: 25px 30px;
				border-bottom: 3px solid #E8E8E8;
				text-align: left;
				.title {
					font-size: 20px;
					font-family: 'Font Regular';
					color: #2F2F32;
					margin-bottom: 30px;
				}
				&-inputs {
					display: flex;
					align-items: center;
					margin-bottom: 12px;
					label {
						font-size: 14px;
						font-family: 'Font Bold';
						color: #313134;
						margin-bottom: 0;
					}
					> input[type="checkbox"]:checked:before {
            content: "";
            height: 100%;
            width: 100%;
            background: url("../assets/img/check.png");
            background-position: center !important;
            background-repeat: no-repeat !important;
          }
          > input[type="checkbox"]:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px;
            border: 0;
          }
          > input[type="checkbox"] {
						border: 1px solid #CECECE;
						border-radius: 1;
            position: relative;
            height: 20px;
            width: 20px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0 !important;
            margin: 0;
						margin-right: 10px;
          }
				}
			}
			&-model {
				padding: 25px 30px;
				border-bottom: 3px solid #E8E8E8;
				text-align: left;
				.title {
					font-size: 20px;
					font-family: 'Font Regular';
					color: #2F2F32;
					margin-bottom: 30px;
				}
				&-select {
					position: relative;
					height: 40px;
					width: 100%;
					select {
						height: 100%;
						width: 100%;
						padding-left: 10px;
						padding-right: 30px;
						border: 1px solid #CBCBCB;
						border-radius: 1px;
						background: var(--white);
						font-size: 14px;
						font-family: 'Font Medium';
						color: #3E3E3E;
						appearance: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						outline: none;
					}
					svg {
						position: absolute;
						right: 13px;
						top: 50%;
						transform: translateY(-50%);
						pointer-events: none;
					}
				}
			}
			&-clear {
				padding: 25px 30px;
				display: flex;
				justify-content: center;
				button {
					background: 0;
					border: 0;
					display: flex;
					align-items: center;
					font-size: 14px;
					font-family: 'Font Bold';
					color: #313134;
					transition: 500ms;
					img {
						margin-left: 10px;
					}
					&:hover,&:focus {
						cursor: pointer;
						opacity: 0.6;
					}
				}
			}
		}
	}
}
</style>