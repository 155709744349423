<template>
	<div>
		<template v-if="loading">
			<PuSkeleton height="200px" />
		</template>
		<section v-else-if="Object.keys(detail).length > 0 && detail.description" class="content-new-ones-intern-observe">
				<div class="container">
					<div class="content-new-ones-intern-observe-header">
						<p class="content-new-ones-intern-observe-header-title">{{ $t("vehicle_used_details.text_observations")|stripHtml }}</p>
					</div>
					<ul class="content-new-ones-intern-observe-itens">
						<div class="content-new-ones-intern-observe-itens-text" v-html="detail.description"></div>
					</ul>
				</div>
			</section>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters('VehicleUsed', ['loading', 'detail'])
	}
}
</script>