<template>
  <section class="content-new-ones-intern">
    <template v-if="loading">
      <PuSkeleton height="400px" />
    </template>
    <div v-else-if="Object.keys(detail).length > 0" class="content-new-ones-intern-itens container">
			<div class="content-new-ones-intern-itens-vehicle col-12 col-lg-5 col-xl-6">
				<p class="content-new-ones-intern-itens-vehicle-brand">{{ detail.version_name }}</p>
				<p class="content-new-ones-intern-itens-vehicle-name">{{ detail.vehicle_used_model.name }}</p>
				<!-- swiper1 -->
				<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
					<swiper-slide v-for="(image, index) in detail.images_path" :key="index">
						<img class="img-fluid" :src="image" alt="image gallery vehicle" title="image gallery vehicle"/>
					</swiper-slide>
					<!-- <div class="swiper-pagination swiper-pagination-gallery" slot="pagination"></div> -->
					<div class="swiper-button-prev swiper-button-prev-gallery" slot="button-prev">
						<svg xmlns="http://www.w3.org/2000/svg" width="43.584" height="74.187" viewBox="0 0 43.584 74.187"><g transform="translate(43.584 74.187) rotate(180)"><g transform="translate(0 0)"><path d="M42.4,34.2,9.387,1.185a4.067,4.067,0,0,0-5.74,0L1.216,3.616a4.064,4.064,0,0,0,0,5.74L28.939,37.078,1.185,64.832a4.068,4.068,0,0,0,0,5.74L3.616,73a4.067,4.067,0,0,0,5.74,0L42.4,39.958a4.1,4.1,0,0,0,0-5.759Z" fill="#c00"/></g></g></svg>
					</div>
					<div class="swiper-button-next swiper-button-next-gallery" slot="button-next">
						<svg style="transform: rotate(180deg)" xmlns="http://www.w3.org/2000/svg" width="43.584" height="74.187" viewBox="0 0 43.584 74.187"><g transform="translate(43.584 74.187) rotate(180)"><g transform="translate(0 0)"><path d="M42.4,34.2,9.387,1.185a4.067,4.067,0,0,0-5.74,0L1.216,3.616a4.064,4.064,0,0,0,0,5.74L28.939,37.078,1.185,64.832a4.068,4.068,0,0,0,0,5.74L3.616,73a4.067,4.067,0,0,0,5.74,0L42.4,39.958a4.1,4.1,0,0,0,0-5.759Z" fill="#c00"/></g></g></svg>
					</div>
				</swiper>
				<!-- swiper2 thumbs -->
				<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
					<swiper-slide v-for="(image, index) in detail.images_path" :key="index">
						<div class="gallery-thumbs-image">
							<img class="img-fluid" :src="image" alt="image gallery vehicle miniatura" title="image gallery vehicle miniatura"/>
						</div>
					</swiper-slide>
				</swiper>
			</div>
			<div class="content-new-ones-intern-itens-piece col-12 col-lg-3 col-xl-3">
				<div class="content-new-ones-intern-itens-piece-header">
					<p>{{ $t("vehicle_used_details.text_to_price")|stripHtml }}</p>
					<p><span>{{ detail.price|money }}</span></p>
					<p>{{ $t("vehicle_used_details.text_cotation")|stripHtml }}</p>
				</div>
				<a class="content-new-ones-intern-itens-piece-call d-lg-none" :href="'tel:'+detail.unit.phone">
					<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20.948" height="20.979">
						<g>
							<path
								d="M16.565 12.995a2.063 2.063 0 0 0-1.5-.686 2.134 2.134 0 0 0-1.514.682l-1.4 1.395c-.115-.062-.23-.12-.341-.177-.159-.08-.31-.155-.438-.235a15.207 15.207 0 0 1-3.644-3.321 8.971 8.971 0 0 1-1.2-1.886c.363-.332.7-.677 1.027-1.01l.372-.376a2.031 2.031 0 0 0 0-3.064l-1.2-1.21c-.137-.137-.279-.279-.412-.421a21.592 21.592 0 0 0-.832-.824A2.1 2.1 0 0 0 4 1.211a2.169 2.169 0 0 0-1.505.651l-.009.009-1.507 1.52A3.239 3.239 0 0 0 .018 5.45a7.762 7.762 0 0 0 .567 3.285 19.064 19.064 0 0 0 3.387 5.65 20.836 20.836 0 0 0 6.938 5.433 10.81 10.81 0 0 0 3.9 1.151c.093 0 .19.009.279.009a3.336 3.336 0 0 0 2.555-1.1c0-.009.013-.013.018-.022a10.066 10.066 0 0 1 .775-.8c.19-.182.385-.372.576-.571a2.209 2.209 0 0 0 .669-1.532A2.128 2.128 0 0 0 19 15.434zm1.585 4.662c-.173.186-.35.354-.54.54a11.645 11.645 0 0 0-.855.886 2.134 2.134 0 0 1-1.665.7h-.2a9.6 9.6 0 0 1-3.454-1.036 19.668 19.668 0 0 1-6.535-5.118A17.975 17.975 0 0 1 1.713 8.32a6.322 6.322 0 0 1-.5-2.772 2.033 2.033 0 0 1 .611-1.315l1.51-1.51a1.006 1.006 0 0 1 .673-.314.947.947 0 0 1 .646.31l.013.013c.27.252.527.514.8.793.137.142.279.283.421.429l1.209 1.209a.845.845 0 0 1 0 1.373c-.128.128-.252.257-.381.381-.372.381-.726.735-1.111 1.08-.009.009-.018.013-.022.022a.9.9 0 0 0-.23 1.005l.013.04a9.7 9.7 0 0 0 1.43 2.333 16.251 16.251 0 0 0 3.932 3.578 6.046 6.046 0 0 0 .545.3c.159.08.31.155.438.235.018.009.035.022.053.031a.96.96 0 0 0 .438.111.947.947 0 0 0 .673-.306l1.514-1.514a1 1 0 0 1 .669-.332.9.9 0 0 1 .638.323l.009.009 2.44 2.44a.875.875 0 0 1 .006 1.385zM11.322 4.99a5.7 5.7 0 0 1 4.64 4.64.594.594 0 0 0 .589.5.791.791 0 0 0 .1-.009.6.6 0 0 0 .491-.691 6.89 6.89 0 0 0-5.614-5.614.6.6 0 0 0-.691.487.591.591 0 0 0 .485.687z">
							</path>
							<path
								d="M20.939 9.254A11.346 11.346 0 0 0 11.694.009a.6.6 0 1 0-.195 1.178 10.134 10.134 0 0 1 8.262 8.262.594.594 0 0 0 .589.5.79.79 0 0 0 .1-.009.587.587 0 0 0 .489-.686z">
							</path>
						</g>
					</svg>
					{{ $t("vehicle_used_details.text_call_now")|stripHtml }}
				</a>
				<div class="content-new-ones-intern-itens-piece-information">
					<div class="content-new-ones-intern-itens-piece-information-elements">
						<div class="content-new-ones-intern-itens-piece-information-elements-svg">
							<svg fill="#000" width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.948 428.948">
								<g fill="#030104">
									<path
										d="M221.988 280.48c9.664 0 17.516-7.889 17.516-17.563 0-9.653-7.852-17.487-17.516-17.487l-79.361-58.371 61.844 76.985c.574 9.164 8.209 16.436 17.517 16.436zM127.008 332.197h174.933v41.513H127.008z">
									</path>
									<path
										d="M366.13 118.057c-40.51-40.509-94.369-62.818-151.656-62.818-57.288 0-111.148 22.309-151.657 62.818S0 212.425 0 269.713c0 36.99 9.319 72.539 26.816 103.997h40.086l29.319-16.929-12.948-22.428-37.7 21.768c-13.319-25.932-20.843-55.307-20.843-86.408 0-5.504.25-10.951.711-16.338l44.952 7.926 4.497-25.504-45.481-8.019c8.127-35.883 26.451-67.937 51.755-92.948l30.815 36.725 19.839-16.646-31.049-37.002c28.471-21.379 63.128-34.938 100.757-37.485v49.117h25.896V80.422c37.629 2.549 72.286 16.107 100.758 37.486l-31.05 37.001 19.838 16.646 30.816-36.726c25.303 25.012 43.627 57.066 51.754 92.948l-45.48 8.021 4.498 25.502 44.949-7.927c.461 5.388.711 10.834.711 16.338 0 31.103-7.521 60.479-20.842 86.409l-37.701-21.766-12.947 22.427 29.318 16.927h40.088c17.498-31.458 26.816-67.007 26.816-103.997.001-57.287-22.31-111.147-62.818-151.654z">
									</path>
								</g>
							</svg>
							<p>{{ $t("vehicle_used_details.field_km") }}</p>
						</div>
						<div class="content-new-ones-intern-itens-piece-information-elements-item">
							<p>{{ detail.km }}</p>
						</div>
					</div>
					<div class="content-new-ones-intern-itens-piece-information-elements">
						<div class="content-new-ones-intern-itens-piece-information-elements-svg">
							<svg fill="#000" width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fal"
								data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path
									d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
									class=""></path>
							</svg>
							<p>{{ $t("vehicle_used_details.field_year") }}</p>
						</div>
						<div class="content-new-ones-intern-itens-piece-information-elements-item">
							<p>{{ detail.year_factory }}/{{ detail.year_model }}</p>
						</div>
					</div>
					<div v-if="detail.fuel" class="content-new-ones-intern-itens-piece-information-elements">
						<div class="content-new-ones-intern-itens-piece-information-elements-svg">
							<svg xmlns="http://www.w3.org/2000/svg" width="26.485" height="28.251">
								<path data-name="Caminho 365"
									d="M15.891 5.297a.883.883 0 0 0-.883-.883H6.18a.883.883 0 0 0-.883.883v7.063a.883.883 0 0 0 .883.883h8.828a.883.883 0 0 0 .883-.883zm-1.766 6.18H7.062v-5.3h7.063z"
									fill="#000"></path>
								<path data-name="Caminho 366"
									d="M26.019 6.26L22.5 4.494a.881.881 0 1 0-.782 1.579l1.341.67a.891.891 0 0 0-.082.306 2.839 2.839 0 0 0 1.743 2.487v10.757a.883.883 0 0 1-1.766 0V13.23a4.688 4.688 0 0 0-3.531-4.33V3.518A3.489 3.489 0 0 0 15.933 0H5.339a3.561 3.561 0 0 0-3.574 3.518v19.76l-1.278.639a.883.883 0 0 0-.488.79v2.649a.931.931 0 0 0 .925.9h19.424a.859.859 0 0 0 .841-.9v-2.649a.883.883 0 0 0-.488-.79l-1.278-.639V10.744a2.858 2.858 0 0 1 1.766 2.486v7.063a2.65 2.65 0 1 0 5.3 0V7.05a.864.864 0 0 0-.47-.79zm-6.6 20.225H1.766v-1.232l1.278-.639a.883.883 0 0 0 .488-.79V3.518A1.793 1.793 0 0 1 5.34 1.765h10.593a1.721 1.721 0 0 1 1.724 1.753v20.306a.883.883 0 0 0 .488.79l1.278.639z"
									fill="#000"></path>
							</svg>
							<p>{{ $t("vehicle_used_details.field_fuel") }}</p>
						</div>
						<div class="content-new-ones-intern-itens-piece-information-elements-item">
							<p>{{ detail.fuel.name }}</p>
						</div>
					</div>
				</div>
				<div class="content-new-ones-intern-itens-piece-social d-lg-none">
					<div class="content-new-ones-intern-itens-piece-social-itens">
						<p>{{ $t("vehicle_used_details.text_share") }}</p>
						<a class="content-new-ones-intern-itens-piece-social-itens-facebook"
							:href="'https://www.facebook.com/sharer/sharer.php?u='+ getUrlLocal"
							target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="9.775" height="18.958">
								<path
									d="M2.851 18.958h3.48v-8.479h2.7l.444-3.369H6.332V4.777a1.9 1.9 0 0 1 .3-1.185A1.719 1.719 0 0 1 8 3.15h1.775v-3A18.339 18.339 0 0 0 7.183.002a4.292 4.292 0 0 0-3.147 1.166 4.39 4.39 0 0 0-1.185 3.277v2.666H0v3.369h2.851z"
									fill="#fff"></path>
							</svg>
						</a>
						<a class="content-new-ones-intern-itens-piece-social-itens-whatsapp"
							href="https://api.whatsapp.com/send?text=Veja o novo https://viatrucks.com.br/seminovo/mercedes-benz-710-710-plus-2p-diesel-2011-manual-17968"
							target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="18.777" height="18.778">
								<path
									d="M13.691 11.253c-.236-.117-1.383-.678-1.6-.756s-.37-.117-.527.117-.6.754-.739.909-.273.164-.505.059a6.35 6.35 0 0 1-1.88-1.162 7.1 7.1 0 0 1-1.3-1.62c-.136-.235-.015-.364.1-.481s.236-.27.353-.409a1.983 1.983 0 0 0 .232-.388.43.43 0 0 0-.02-.41c-.059-.117-.526-1.267-.721-1.726s-.381-.4-.526-.4-.29-.012-.447-.012a.882.882 0 0 0-.624.281 2.612 2.612 0 0 0-.818 1.936A4.578 4.578 0 0 0 5.631 9.6a10.433 10.433 0 0 0 3.99 3.509c.559.235.994.376 1.333.492a3.237 3.237 0 0 0 1.471.095A2.408 2.408 0 0 0 14 12.579a1.937 1.937 0 0 0 .141-1.115c-.058-.106-.211-.164-.446-.27m-4.258 5.823h-.013a7.827 7.827 0 0 1-3.955-1.08l-.282-.167-2.934.763.786-2.852-.187-.293a7.76 7.76 0 0 1 12.086-9.573 7.658 7.658 0 0 1 2.276 5.469 7.769 7.769 0 0 1-7.773 7.734M16.055 2.7A9.5 9.5 0 0 0 9.424 0a9.3 9.3 0 0 0-8.1 13.957L0 18.778l4.957-1.293a9.438 9.438 0 0 0 4.468 1.132 9.342 9.342 0 0 0 9.353-9.307 9.228 9.228 0 0 0-2.735-6.581"
									fill="#fff"></path>
							</svg>
						</a>
					</div>
				</div>
			</div>
			<div class="content-new-ones-intern-itens-form col-12 col-lg-4 col-xl-3">
				<FormCotation :tabMenuActive="tabMenuActive" />
			</div>
		</div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import FormCotation from "@/components/Forms";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
    FormCotation,
  },
  computed: {
    ...mapGetters("VehicleUsed", ["loading", "detail"]),
	getUrlLocal(){
		return document.location.href;
	}
  },
  mounted() {
    this.$nextTick(() => {
		const swiperTop = this.$refs.swiperTop.$swiper;
		const swiperThumbs = this.$refs.swiperThumbs.$swiper;
		swiperTop.controller.control = swiperThumbs;
		swiperThumbs.controller.control = swiperTop;
	});
  },
  data() {
    return {
      tabMenuActive: "used",
      swiperOptionTop: {
        notNextTick: true,
        spaceBetween: 10,
        speed: 800,
        pagination: {
          el: ".swiper-pagination-gallery",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-gallery",
          prevEl: ".swiper-button-prev-gallery",
        },
      },
      swiperOptionThumbs: {
        notNextTick: true,
        spaceBetween: 4.6,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        speed: 800,
      },
    };
  },
};
</script>