<template>
  <div>
    <template v-if="loading">
      <PuSkeleton height="200px" />
    </template>
    <section
      v-else-if="other_vehicles && other_vehicles.length > 0"
      class="content-home-new-ones"
    >
      <div class="content-home-new-ones-itens container">
        <div class="content-home-new-ones-itens-header">
          <h2 class="title">
            {{ $t("vehicle_used_details.text_other_vehicles") | stripHtml }}
          </h2>
        </div>
        <div class="content-home-new-ones-itens-body">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-lg-4 col-xl-3"
              v-for="(vehicle, index) in other_vehicles"
              :key="index"
            >
              <CardVehicleUsed :vehicle="vehicle" />
            </div>
          </div>
          <div class="content-btn">
            <router-link tag="a" :to="{name: 'vehicleUseds'}" class="btn-view-plus">
              + Ver mais
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CardVehicleUsed from "@/components/cards/CardVehicleUsed.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CardVehicleUsed,
  },
  computed: {
    ...mapGetters("VehicleUsed", ["loading", "other_vehicles"]),
  },
};
</script>